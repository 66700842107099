<template>
	<div>
		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar-three-line" class="mx-auto mb-5 rounded-xl" max-width="1200" />
			</template>
			<template v-else>
				<div class="text-center my-2" key="topPagination" v-if="posts.length">
					<v-pagination :value="page" :length="pages" total-visible="10" @input="goToPage" />
				</div>
				<FeedPost v-for="(post, index) in posts" :key="index" :post="post" />
				<div class="text-center my-2" key="bottomPagination" v-if="posts.length">
					<v-pagination :value="page" :length="pages" total-visible="10" @input="goToPage" />
				</div>
			</template>
		</v-fade-transition>

		<h3 v-if="!loading && posts.length == 0 && setupInfo.accountType.student.value" class="m-4">
			{{ $t('newsfeed.emptyFeedMessage') }}
		</h3>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'FeedPosts',
	components: {
		FeedPost: () => import('@/components/feed/FeedPost.vue'),
	},
	props: {
		posts: {
			type: Array,
			required: true,
		},
		page: {
			type: Number,
			default: 1,
		},
		perPage: {
			type: Number,
			default: 10,
		},
		pages: {
			type: Number,
			default: 1,
		},
	},
	computed: {
		...mapGetters({
			loading: 'feed/loading',
			setupInfo: 'user/setupInfo',
		}),
	},
	methods: {
		goToPage(page) {
			this.$emit('updateList', page, this.perPage)
		},
	},
}
</script>

<style lang="scss" scoped></style>
